import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export default function SGCloseSubscriptionService({ region }) {
  return (
    <div className="close-subscription">
      <br />
      <h5>Thank you for choosing Allied World.</h5>
      <br />
      <p>Dear Valued Customer</p>
      <p>
        Referring to our letter sent to you earlier, the deadline to complete
        online set up of your monthly premium payment has passed and your policy
        has therefore been cancelled.
      </p>
      <p>
        Should you have any questions, please email{" "}
        <a href="mailto:sg.customerservice@awac.com" style={{ color: "black" }}>
          sg.customerservice@awac.com
        </a>{" "}
        or call us on{" "}
        <a href="tel:+65 6423 0888" style={{ color: "black" }}>
          +65 6423 0888
        </a>{" "}
        (Mon-Fri excluding Public Holidays 8:30am to 5:30pm).
      </p>
      <p>
        Yours sincerely,
        <br />
        Your Allied World customer service team
      </p>
    </div>
  );
}
