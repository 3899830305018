import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from './Footer';
import '../userforms/UserForm.css';
import './Service.css';
import { useParams } from "react-router-dom";

export default function RecurringPaymentSuccessServices() {
  let pathParam = useParams();
    console.log("in success page", pathParam[0]);
  return (
    <React.Fragment>
      <nav className="navbar navbar-light">
        <a
          className="navbar-brand logo-style"
          href={process.env.REACT_APP_HOME_URL}
        >
          <img className="d-block d-sm-none headermobilelogo" src="/AW.png" alt="logo" />
          <img
              className="d-none d-sm-block d-md-block d-lg-block d-xl-block headerdesktoplogo"
              src="/HeaderLogo.jpg"
              alt="logo"
          />
        </a>
      </nav>
      <div className="container-sm mx-auto">
        <div
          align="center"
        >
          <div className="col-sm-9 col-sm-offset-9 successpagedivtag">
            <br />
            <br />
            <h3>Thank you for choosing to insure with Allied World.</h3>
            <p>We have received your payment and set up your recurring payments. You will shortly receive a receipt via email.</p>
            <p className="successpageptag">
            Should you have any questions please contact us on
            {
              (pathParam[0] === "hk") ? (<React.Fragment>
                <a href="tel:+85229683000" style={{ color: "black" }}> +852 2968 3000</a> or email <a href="mailto:hkhotline@awac.com" style={{ color: "black" }}> hkhotline@awac.com</a>
              </React.Fragment>)
              : (<React.Fragment>
                <a href="tel:+6564230888" style={{ color: "black" }}> +65 6423 0888</a> or email <a href="mailto:sg.customerservice@awac.com" style={{ color: "black" }}> sg.customerservice@awac.com</a>
              </React.Fragment>)
            }
            </p>
            <p>Yours sincerely,</p>
            <p>The Allied World customer service team</p>
            {
              (pathParam[0] === "hk") ? 
              (
                <React.Fragment>
                  <p><strong>Allied World Assurance Company, Ltd (Hong Kong Branch) (incorporated in Bermuda with limited liability)</strong></p>
                  <br />
                  <h3>多謝您選擇 Allied World世聯所提供的保障。</h3>
                  <p>我們已經收到您的款項並設立您的定期繳費指示，您稍後便可透過電郵收取電子收據。</p>
                  <p className="successpageptag">
                  如有任何疑問，請致電 <a href="tel:+85229683000" style={{ color: "black" }}> +852 2968 3000 </a>或電郵至 <a href="mailto:hkhotline@awac.com" style={{ color: "black" }}> hkhotline@awac.com</a> 與我們聯絡。
                  </p>
                  <p>Allied World 世聯客戶服務團隊,</p>
                  <p>謹啟</p>
                  <p><strong>Allied World Assurance Company, Ltd 世聯保險有限公司 (香港分行) (於百慕達註冊成立的有限公司)</strong></p>
                </React.Fragment>
              ) : 
              (<p><strong>Allied World Assurance Company, Ltd (Singapore Branch)</strong></p>)
            }
            <br />
            <br />
          </div>
        </div>
        <div className="footer-end pt-5">
          <div align="center" className="container-sm">
            <br />
            <Footer region={pathParam[0]}/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}