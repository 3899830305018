import React, { useEffect, useState } from "react";
import { BrowserRouter, useParams } from "react-router-dom";
import Routes from "./Routes";
import "./App.css";
import Bowser from "bowser";
import UnsupportedBrowserCheckSerivce from "./services/UnsupportedBrowserCheckSerivce";
import { ENV_VAR } from "./common/constants";

function App() {
  const [isValidBrowser, setisValidBrowser] = useState(false);
  const [isValidated, setisValidated] = useState(false);

  let region = window.location.pathname.split("/")[1];

  let homeUrl = window.location.href;

  console.log("===> region in pathname is ", region);
  console.log("====> Current URL is ", homeUrl);

  useEffect(() => {
    if (!isValidated) {
      validateBrowser();
      setisValidated(true);
    }
  });

  const validateBrowser = async () => {
    try {
      const browser = Bowser.getParser(window.navigator.userAgent);
      console.log("===> Browser. ", JSON.stringify(browser.getBrowser()));
      const isValidBrowser = await new Promise((resolve, reject) => {
        try {
          const test = browser.satisfies({
            chrome: ">=29",
            firefox: ">=27",
            safari: ">=7",
            edge: ">=12",
          });
          resolve(test);
        } catch (error) {
          reject(error);
        }
      });
      console.log("===> isValidBrowser ", isValidBrowser);
      setisValidBrowser(isValidBrowser);
    } catch (error) {
      window.location.href =
        region === "hk" ? ENV_VAR.hk_failure_url : ENV_VAR.sg_failure_url;
    }
  };

  return (
    <BrowserRouter>
      {isValidBrowser ? (
        <Routes />
      ) : (
        <UnsupportedBrowserCheckSerivce region={region} homeUrl={homeUrl} />
      )}
    </BrowserRouter>
  );
}

export default App;
