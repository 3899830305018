import React, {useState, useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from 'react-router-dom';
import {ROUTE_PATHS} from '../common/constants';
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import {APP_DETAILS} from '../common/constants';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import './UserForm.css';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "white",
      color: "#b31b34",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9"
    }
  }))(Tooltip);

/* 
Annual Mode App
*/

export default function GenerateForm({region}) {

    const [policyNumber, setpolicyNumber] = useState("");
    const [insuredName, setinsuredName] = useState("");
    const [userEmail, setuserEmail] = useState("");
    const [amountToPay, setamountToPay] = useState(0);
    const [userPhoneNumber, setuserPhoneNumber] = useState(0);
    const [openPolicyNumber, setOpenPolicyNumber] = React.useState(false);
    const [openInsuredName, setOpenInsuredName] = React.useState(false);
    const [openEmail, setOpenEmail] = React.useState(false);
    const [openPay, setOpenPay] = React.useState(false);
    const [openUserPhone, setOpenUserPhone] = useState(false);
    const [isSG, setisSG] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if(policyNumber !== null && policyNumber !== "" && insuredName !== null && insuredName !== "" && userEmail !== null && userEmail !== "" && amountToPay !== 0){
            let paybtn = document.getElementById("payButton");
            paybtn.disabled = false;
        }else {
            let paybtn = document.getElementById("payButton");
            paybtn.disabled = true;
        }
        if(region === "sg"){
            setisSG(true);
        }else{
            setisSG(false);
        }
    })

    const handleTooltipClose = fieldType => {
        if(fieldType === "policyNumber"){
            setOpenPolicyNumber(false);
        }else if(fieldType === "insuredName") {
            setOpenInsuredName(false);
        }else if(fieldType === "email") {
            setOpenEmail(false);
        }else if(fieldType === "pay") {
            setOpenPay(false);
        }else if(fieldType === "phoneNumber") {
            setOpenUserPhone(false);
        }
    };

    const handleTooltipOpen = fieldType => {
        if(fieldType === "policyNumber"){
            setOpenPolicyNumber(true);
        }else if(fieldType === "insuredName") {
            setOpenInsuredName(true);
        }else if(fieldType === "email") {
            setOpenEmail(true);
        }else if(fieldType === "pay") {
            setOpenPay(true);
        }else if(fieldType === "phoneNumber") {
            setOpenUserPhone(true);
        }
    };

    const handlePrePaymentAction = (event) => {
        let formDetails = {
            PolicyNumber: policyNumber,
            InsuredName: insuredName,
            Email: userEmail,
            Amount: Math.trunc(amountToPay),
            PhoneNumber: userPhoneNumber,
            StripeMode: APP_DETAILS.stripeAnnualMode,
            AppName: APP_DETAILS.manualApplicationName,
            Region: region,
        }
        console.log("===> form details in generate form ", formDetails);
        history.push(ROUTE_PATHS.paymentService, {
            detail: JSON.stringify(formDetails),
        });
    }

    return (
        <div>
            <p><strong>Thank you for choosing Allied World.</strong></p>
            <p>Please provide the following details so we can process your payment. Upon successful payment you will receive a receipt to the email address you provide below.</p>
            {
            !isSG ? (<React.Fragment>
                <p>Should you have any questions please contact us on <a href="tel:+85229683000" style={{ color: "black" }}>+852 2968 3000</a> or email <a href="mailto:hkhotline@awac.com" style={{ color: "black" }}>hkhotline@awac.com</a>.</p>
                <br />
                <p>多謝您選擇 Allied World 世聯。</p>
                <p>請提供以下詳細資料，以便我們進一步處理您的繳款。成功付款後，電子收據將會被發送至以下您填寫的電郵信箱。</p>
                <p>如有任何疑問，請致電<a href="tel:+85229683000" style={{ color: "black" }}> +852 2968 3000</a> 或電郵至<a href="mailto:hkhotline@awac.com" style={{ color: "black" }}> hkhotline@awac.com</a> 與我們聯絡。</p>
            </React.Fragment>) : 
            (<p>Should you have any questions please contact us on <a href="tel:+6564230888" style={{ color: "black" }}>+65 6423 0888</a> or email <a href="mailto:sg.customerservice@awac.com" style={{ color: "black" }}>sg.customerservice@awac.com</a>.</p>)
            }
            <form onSubmit={handlePrePaymentAction}>
                <div className="form-group col-xs-2">
                    <p className="input-group">
                        <input type="text" className="form-control" id="policyNumber" aria-describedby="policynumberHelp" required placeholder={isSG ? "Policy Number*" : "Policy Number 保單號碼*"} onChange={(e) => setpolicyNumber(e.target.value)} />
                        <ClickAwayListener onClickAway={() => handleTooltipClose("policyNumber")}>
                        <HtmlTooltip
                            PopperProps={{
                                disablePortal: false
                            }}
                            onClose={() => handleTooltipClose("policyNumber")}
                            open={openPolicyNumber}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                                <React.Fragment>
                                    <p>As shown on your Allied World policy document or renewal notice. For renewals, please enter your Renewal policy no.</p>
                                    {
                                        !isSG ? (<p>如續保，請填寫你的續保保單號碼。(等同您的 Allied World 世聯保單文件或續保通知上所顯示)</p>) : ""
                                    }
                                </React.Fragment>
                            }
                            interactive>
                            <Button onClick={() => handleTooltipOpen("policyNumber")} style={{outline: "none", border: "none"}}><i className="fa fa-info-circle" /></Button>
                        </HtmlTooltip>
                        </ClickAwayListener>
                    </p>
                </div>
                <div className="form-group col-xs-2">
                    <p className="input-group">
                        <input type="text" className="form-control" id="insuredName" aria-describedby="insuredNameHelp" required placeholder={isSG ? "Insured Name*" : "Insured Name 受保人名稱*"} onChange={(e) => setinsuredName(e.target.value)} />
                        <ClickAwayListener onClickAway={() => handleTooltipClose("insuredName")}>
                        <HtmlTooltip 
                            PopperProps={{
                                disablePortal: false
                            }}
                            onClose={() => handleTooltipClose("insuredName")}
                            open={openInsuredName}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                                <React.Fragment>
                                    <p>As shown on your Allied World policy document or renewal notice</p>
                                    {
                                        !isSG ? (<p>等同您的 Allied World 世聯保單文件或續保通知上所顯示</p>) : ""
                                    }
                                </React.Fragment>
                            }
                            interactive>
                            <Button onClick={() => handleTooltipOpen("insuredName")} style={{outline: "none", border: "none"}}><i className="fa fa-info-circle" /></Button>
                        </HtmlTooltip>
                        </ClickAwayListener>
                    </p>
                </div>
                <div className="form-group col-xs-2">
                    <p className="input-group">
                        <input type="email" className="form-control" id="userEmail" aria-describedby="emailHelp" required placeholder={isSG ? "Email*" : "Email 電郵地址*"} onChange={(e) => setuserEmail(e.target.value)} />
                        <ClickAwayListener onClickAway={() => handleTooltipClose("email")}>
                        <HtmlTooltip 
                            PopperProps={{
                                disablePortal: false
                            }}
                            onClose={() => handleTooltipClose("email")}
                            open={openEmail}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                                <React.Fragment>
                                    <p>Your receipt will be sent to this email address</p>
                                    {
                                        !isSG ? (<p>您的電子收據將會被發送此電郵信箱</p>) : ""
                                    }
                                </React.Fragment>
                            } 
                            interactive>
                            <Button onClick={() => handleTooltipOpen("email")} style={{outline: "none", border: "none"}}><i className="fa fa-info-circle" /></Button>
                        </HtmlTooltip>
                        </ClickAwayListener>
                    </p>
                </div>
                <div className="form-group col-xs-2">
                    <p className="input-group">
                        <input type="text" className="form-control" id="amountToPay" pattern="[0-9]+([,.][0-9]+)?" title={isSG ? "Please enter numbers only" : "Please enter numbers only 請只輸入數字"} required placeholder={isSG ? "Amount (SG$)*" : "Amount (HK$) 應付金額（港幣/元)*"} onChange={(e) => setamountToPay((e.target.value * 100).toFixed(0))} />
                        <ClickAwayListener onClickAway={() => handleTooltipClose("pay")}>
                        <HtmlTooltip 
                            PopperProps={{
                                disablePortal: false
                            }}
                            onClose={() => handleTooltipClose("pay")}
                            open={openPay}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                                <React.Fragment>
                                    <p>Please ensure this exactly matches the amount on your Allied World invoice or renewal notice</p>
                                    {
                                        !isSG ? (<p>請確保此金額與您的 Allied World 世聯發票或續保通知上所顯示的完全相同</p>) : ""
                                    }
                                </React.Fragment>
                            }
                            interactive>
                            <Button onClick={() => handleTooltipOpen("pay")} style={{outline: "none", border: "none"}}><i className="fa fa-info-circle" /></Button>
                        </HtmlTooltip>
                        </ClickAwayListener>
                    </p>
                </div>
                <div className="form-group col-xs-2">
                    <p className="input-group">
                        <input type="tel" className="form-control" id="phone" name="phone" title={isSG ? "Please enter numbers only" : "Please enter numbers only 請只輸入數字"} placeholder={isSG ? "Mobile Phone No" : "Mobile Phone No 手機號碼"} pattern="[0-9]+" onChange={(e) => setuserPhoneNumber(e.target.value)} />
                        <ClickAwayListener onClickAway={() => handleTooltipClose("phoneNumber")}>
                        <HtmlTooltip 
                            PopperProps={{
                                disablePortal: false
                            }}
                            onClose={() => handleTooltipClose("phoneNumber")}
                            open={openUserPhone}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                                <React.Fragment>
                                    <p>The most suitable mobile number for us to confirm your payment details</p>
                                    {
                                        !isSG ? (<p>提供最適合的手機號碼以便我們與您確認付款詳情。</p>) : ""
                                    }
                                </React.Fragment>
                            }
                            interactive>
                            <Button onClick={() => handleTooltipOpen("phoneNumber")} style={{outline: "none", border: "none"}}><i className="fa fa-info-circle" /></Button>
                        </HtmlTooltip>
                        </ClickAwayListener>
                    </p>
                </div>
                <button type="submit" id="payButton" className="btn btn-primary submit-btn" disabled >{isSG ? "Proceed To Pay" : "Proceed To Pay 前往付款"}</button>
            </form>
        </div>
    )
}
