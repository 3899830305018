import React from "react";
import Footer from "./Footer";
import "../userforms/UserForm.css";
import "./Service.css";
import { useParams } from "react-router-dom";

export default function PaymentFailureService() {
  let pathParam = useParams();
  console.log("in failure page", pathParam[0]);
  return (
    <React.Fragment>
      <nav className="navbar navbar-light">
        <a
          className="navbar-brand logo-style"
          href={process.env.REACT_APP_HOME_URL}
        >
          <img
            className="d-block d-sm-none headermobilelogo"
            src="/AW.png"
            alt="logo"
          />
          <img
            className="d-none d-sm-block d-md-block d-lg-block headerdesktoplogo"
            src="/HeaderLogo.jpg"
            alt="logo"
          />
        </a>
      </nav>
      <div className="container-sm mx-auto">
        <div align="center">
          <div className="col-sm-9 col-sm-offset-9">
            <br />
            <br />
            <h3>Error occurred while processing your request.</h3>
            <p className="errorpageptag">
              Please try again (or) call us at{" "}
              {pathParam[0] === "hk" ? (
                <React.Fragment>
                  <a href="tel:+85229683000" style={{ color: "black" }}>
                    {" "}
                    +852 2968 3000
                  </a>{" "}
                  or email{" "}
                  <a
                    href="mailto:hkhotline@awac.com"
                    style={{ color: "black" }}
                  >
                    {" "}
                    hkhotline@awac.com
                  </a>
                </React.Fragment>
              ) : pathParam[0] === "sg" ? (
                <React.Fragment>
                  <a href="tel:+6564230888" style={{ color: "black" }}>
                    {" "}
                    +65 6423 0888
                  </a>{" "}
                  or email{" "}
                  <a
                    href="mailto:sg.customerservice@awac.com"
                    style={{ color: "black" }}
                  >
                    {" "}
                    sg.customerservice@awac.com
                  </a>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <br />
                  <br />
                  <p>
                    Singapore :{" "}
                    <a href="tel:+6564230888" style={{ color: "black" }}>
                      {" "}
                      +65 6423 0888
                    </a>{" "}
                    or email{" "}
                    <a
                      href="mailto:sg.customerservice@awac.com"
                      style={{ color: "black" }}
                    >
                      {" "}
                      sg.customerservice@awac.com
                    </a>
                  </p>
                  <p>
                    HongKong :{" "}
                    <a href="tel:+85229683000" style={{ color: "black" }}>
                      {" "}
                      +852 2968 3000
                    </a>{" "}
                    or email{" "}
                    <a
                      href="mailto:hkhotline@awac.com"
                      style={{ color: "black" }}
                    >
                      {" "}
                      hkhotline@awac.com
                    </a>
                  </p>
                </React.Fragment>
              )}{" "}
              for further assistance.
            </p>
            <p>Yours sincerely,</p>
            <p>The Allied World customer service team</p>
            {pathParam[0] === "hk" ? (
              <p>
                <strong>
                  Allied World Assurance Company, Ltd (Hong Kong Branch)
                  (incorporated in Bermuda with limited liability)
                </strong>
              </p>
            ) : pathParam[0] === "sg" ? (
              <p>
                <strong>
                  Allied World Assurance Company, Ltd (Singapore Branch)
                </strong>
              </p>
            ) : (
              <p>
                <strong>
                  Allied World Assurance Company, Ltd (Singapore Branch & Hong
                  Kong Branch - incorporated in Bermuda with limited liability)
                </strong>
              </p>
            )}
            <br />
            <br />
          </div>
        </div>
        <div className="footer-end pt-5">
          <div align="center" className="container-sm">
            <br />
            <Footer region={pathParam[0]} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
