import React from "react";
import { Route, Switch } from "react-router-dom";
import UserInformation from './userforms/UserInformation';
import UserInformationWithParams from './userforms/UserInformationWithParams';
import StripePaymentService from './services/StripePaymentService';
import PaymentSuccessServices from './services/PaymentSuccessServices';
import RecurringPaymentSuccessServices from './services/RecurringPaymentSuccessServices'
import PaymentFailureService from './services/PaymentFailureService';
import UnsupportedBrowserCheckSerivce from './services/UnsupportedBrowserCheckSerivce';
import UserDetailsSubscribedMessageService from './services/UserDetailsSubscribedMessageService';
import {ROUTE_PATHS} from './common/constants';

export default function Routes() {
    return (
        <React.Fragment>
        <Switch>
          <Route exact path={ROUTE_PATHS.successPage} component={PaymentSuccessServices} />
          <Route exact path={ROUTE_PATHS.recurringPaymentSuccessPage} component={RecurringPaymentSuccessServices} />
          <Route exact path={ROUTE_PATHS.failurePage} component={PaymentFailureService} />
          <Route exact path={ROUTE_PATHS.unsupportedBrowserPage} component={UnsupportedBrowserCheckSerivce} />
          <Route exact path={ROUTE_PATHS.paymentService} component={StripePaymentService} />
          <Route exact path={ROUTE_PATHS.userDetailsMessagePage} component={UserDetailsSubscribedMessageService} />
          <Route exact path="/(hk|sg)/subscribe/:userid" component={UserInformationWithParams} />
          <Route exact path={ROUTE_PATHS.home} component={UserInformation} />
        </Switch>
      </React.Fragment>
    )
}
