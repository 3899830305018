import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import Axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { ENV_VAR, APP_DETAILS} from '../common/constants';

let stripePromise = "";

export default function StripePaymentService() {

  const [hasCheckoutCalled, sethasCheckoutCalled] = useState(false);

    let history = useHistory();
    let formDetails = JSON.parse(history.location.state.detail);

    console.log("Form Details in Payment Service",formDetails);

    let insuredData = insuredDetailsForStripe(formDetails);

    stripePromise = (insuredData.Region === "sg") ? loadStripe(ENV_VAR.sg_stripe_public_key) : loadStripe(ENV_VAR.hk_stripe_public_key);

    console.log(
        "====> Insured Data sent to stripe is :: ",
        JSON.stringify(insuredData)
    );


    const stripePayment = async (insuredData) => {
      console.log("===> post URL is ",ENV_VAR.session_obj_url);
      if(!hasCheckoutCalled){
        sethasCheckoutCalled(true);
      Axios.post(ENV_VAR.session_obj_url, insuredData)
        .then(async (response) => {
          const stripe = await stripePromise;
    
          const { error } = stripe.redirectToCheckout({
            sessionId: response.data.id,
          });
    
          if (error) {
            console.log(" Error while checking out :: ", error);
          }
        })
        .catch((error) => {
          console.log(" Error while requesting the session object : ", error);
          window.location.href = (insuredData.Region === "sg") ? ENV_VAR.sg_failure_url : ENV_VAR.hk_failure_url;
        });
      }
      
    };

    stripePayment(insuredData);

    return (
        <div
        align="center"
        className="jumbotron"
        style={{ backgroundColor: "white" }}
      >
        <div className="col-sm-9 col-sm-offset-9">
          <p>
            <b>Redirecting to Payment Gateway...</b>
          </p>
          <br />
        </div>
        <Loader type="Oval" color="#9e272a" height={70} width={90} />
      </div>
    )
}

const insuredDetailsForStripe = (formDetails) => {
  let currency = (formDetails.Region === "sg") ? APP_DETAILS.sgCurrency : APP_DETAILS.hkCurrency;
  let successUrl = "";
  if(formDetails.AppName === APP_DETAILS.recurringApplicationName) {
    successUrl = (formDetails.Region === "sg") ? ENV_VAR.sg_rp_success_url : ENV_VAR.hk_rp_success_url;
  }else {
    successUrl = (formDetails.Region === "sg") ? ENV_VAR.sg_success_url : ENV_VAR.hk_success_url;
  }
  let failureUrl = (formDetails.Region === "sg") ? ENV_VAR.sg_failure_url : ENV_VAR.hk_failure_url;
    const insuredData = {
      ...formDetails,
      Currency: currency,
      SuccessUrl: successUrl,
      CancelUrl: failureUrl,
    };
    return insuredData;
  };

