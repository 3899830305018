import React, { useEffect, useState } from "react";
import "./UserForm.css";
import GenerateFormWithParams from "./GenerateFormWithParams";
import CloseSubscriptionService from "../services/CloseSubscriptionService";
import SGCloseSubscriptionService from "../services/SGCloseSubscriptionService";
import { useParams } from "react-router-dom";
import Footer from "../services/Footer";

export default function UserInformationWithParams(props) {
  const { userid } = useParams();
  console.log("===> userid ", userid);
  let pathParam = useParams();
  console.log("in userinfo", pathParam[0]);

  return (
    <div>
      <nav className="navbar navbar-light">
        <a className="navbar-brand" href="#">
          <img
            className="d-block d-sm-none headermobilelogo"
            src="/AW.png"
            alt="logo"
          />
          <img
            className="d-none d-sm-none d-md-block d-lg-block headerdesktoplogo"
            src="/HeaderLogo.jpg"
            alt="logo"
          />
        </a>
      </nav>
      <div className="container-sm body-render-container">
        {pathParam[0] === "hk" ? (
          <CloseSubscriptionService region="hk" />
        ) : (
          <SGCloseSubscriptionService region="sg" />
        )}
      </div>
      <div align="center" className="pt-5">
        {pathParam[0] === "hk" ? (
          <p className="mt-3">
            <strong>
              Allied World Assurance Company, Ltd (Hong Kong Branch)
              (incorporated in Bermuda with limited liability) <br /> Allied
              World Assurance Company, Ltd 世聯保險有限公司 (香港分行)
              (於百慕達註冊成立的有限公司)
            </strong>
          </p>
        ) : (
          <p className="mt-3">
            <strong>
              Allied World Assurance Company, Ltd (Singapore Branch)
            </strong>
          </p>
        )}
        <Footer region={pathParam[0]} />
      </div>
    </div>
  );
}
