import React from "react";
import { ENV_VAR } from '../common/constants';

export default function Footer({region}) {
  console.log("===> in footer ", region);
  return (
    <React.Fragment>
      <a
        href={region === "hk" ? ENV_VAR.hk_privacy_url : ENV_VAR.sg_privacy_url}
        target="_blank"
        rel="noreferrer noopener"
      >
        <span style={{ color: "#9e272a" }}>Privacy Policy</span>
      </a>{" "}
      |{" "}
      <a href={ENV_VAR.aw_terms_url} target="_blank" rel="noreferrer noopener">
        <span style={{ color: "#9e272a" }}>Terms of Use</span>
      </a>{" "}
      | © 2020 Allied World Assurance Company Holdings, Ltd. All rights
      reserved.
    </React.Fragment>
  );
}