import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export default function CloseSubscriptionService({ region }) {
  return (
    <div className="close-subscription">
      <h5>多謝您選擇Allied World世聯所提供的保障。</h5>
      <br />
      <p>親愛的客戶</p>
      <p>
        鑑於較早前我們向閣下發出的書面通知，有關設置網上保險月費的繳款更新期限已完結，因此您的保單已被取消。
      </p>
      <p>
        如有任何疑問，請電郵至{" "}
        <a href="mailto:hkgi@awac.com" style={{ color: "black" }}>
          hkgi@awac.com
        </a>{" "}
        或致電{" "}
        <a href="tel:+85229683000" style={{ color: "black" }}>
          +852 2968 3000
        </a>{" "}
        (星期一至五，上午9時正至下午12時45分及下午2時正至5時30分) 與我們聯絡。
      </p>
      <p>
        <a href="https://donline.alliedworldgroup.com.hk/app/menu.jsp?c=M9">
          Allied World世聯保險網上平台
        </a>
      </p>{" "}
      <p>
        你的 Allied World 世聯客戶服務團隊
        <br />
        謹啟
      </p>
      <br />
      <h5>Thank you for choosing Allied World.</h5>
      <br />
      <p>Dear Valued Customer</p>
      <p>
        Referring to our letter sent to you earlier, the deadline to complete
        online set up of your monthly premium payment has passed and your policy
        has therefore been cancelled.
      </p>
      <p>
        Should you have any questions, please contact us at email{" "}
        <a href="mailto:hkgi@awac.com" style={{ color: "black" }}>
          hkgi@awac.com
        </a>{" "}
        or on{" "}
        <a href="tel:+85229683000" style={{ color: "black" }}>
          +852 2968 3000
        </a>{" "}
        (Mon-Fri excluding Public Holidays 9:00am to 12:45pm & 2:00pm to
        5:30pm).
      </p>
      <p>
        <a href="https://donline.alliedworldgroup.com.hk/app/menu.jsp?c=M9">
          Allied World Insurance Online Purchase
        </a>
      </p>
      <p>
        Yours sincerely,
        <br />
        Your Allied World customer service team
      </p>
    </div>
  );
}
