export const ROUTE_PATHS = {
    home: "/(hk|sg)/paybill",
    userForm: "/generateForm",
    currentUserForm: "/userFormWithParams",
    paymentService: "/stripePaymentService",
    successPage: "/(hk|sg)/successPage",
    recurringPaymentSuccessPage: "/(hk|sg)/recurringPaymentSuccessPage",
    failurePage: "/(hk|sg)/failurePage",
    unsupportedBrowserPage: "/unsupportedBrowser",
    userDetailsMessagePage: "/(hk|sg)/subscribed"
}

export const ENV_VAR = {
    hk_success_url: process.env.REACT_APP_HK_SUCCESS_URL,
    sg_success_url: process.env.REACT_APP_SG_SUCCESS_URL,
    hk_rp_success_url: process.env.REACT_APP_RP_HK_SUCCESS_URL,
    sg_rp_success_url: process.env.REACT_APP_RP_SG_SUCCESS_URL,
    hk_failure_url: process.env.REACT_APP_HK_FAILURE_URL,
    sg_failure_url: process.env.REACT_APP_SG_FAILURE_URL,
    session_obj_url: process.env.REACT_APP_SESSION_OBJ_URL,
    hk_stripe_public_key: process.env.REACT_APP_HK_STRIPE_PUBLC_KEY,
    sg_stripe_public_key: process.env.REACT_APP_SG_STRIPE_PUBLC_KEY,
    fetch_user_details_url: process.env.REACT_APP_FETCH_USER_DETAILS_ENDPOINT,
    hk_privacy_url: process.env.REACT_APP_HK_PRIVACY_URL,
    sg_privacy_url: process.env.REACT_APP_SG_PRIVACY_URL,
    hk_subscribed_url: process.env.REACT_APP_HK_SUBSCRIBED_URL,
    sg_subscribed_url: process.env.REACT_APP_SG_SUBSCRIBED_URL,
    aw_terms_url: process.env.REACT_APP_AW_TERMS_URL
  };

export const APP_DETAILS = {
    hkCurrency: "HKD",
    sgCurrency: "SGD",
    manualApplicationName: "manual_payment",
    recurringApplicationName: "recurring_payment",
    stripeRecurringMode: "subscription",
    stripeAnnualMode: "payment",
  };

