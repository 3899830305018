import React from "react";
import Footer from "./Footer";

export default function UnsupportedBrowserCheckSerivce({ region, homeUrl }) {
  return (
    <React.Fragment>
      <nav className="navbar navbar-light">
        <a
          className="navbar-brand logo-style"
          href={process.env.REACT_APP_HOME_URL}
        >
          <img
            className="d-block d-sm-none headermobilelogo"
            src="/AW.png"
            alt="logo"
          />
          <img
            className="d-none d-sm-block d-md-block d-lg-block headerdesktoplogo"
            src="/HeaderLogo.jpg"
            alt="logo"
          />
        </a>
      </nav>
      <div className="container-sm mx-auto">
        <div align="center">
          <div className="col-sm-9 col-sm-offset-9">
            <br />
            <br />
            <h6>
              {region === "hk" ? (
                <p>
                  The page you were attempting to reach{" "}
                  <a href={homeUrl} style={{ color: "black" }}>
                    {homeUrl}
                  </a>{" "}
                  only supports the browsers listed below. Please try again
                  using a supported browser. <br />
                  <br />
                  您嘗試瀏覽的網站{" "}
                  <a href={homeUrl} style={{ color: "black" }}>
                    {homeUrl}
                  </a>{" "}
                  僅支援下列的瀏覽器。 請使用有效支援的瀏覽器再重試。
                </p>
              ) : (
                <p>
                  The page you were attempting to reach{" "}
                  <a href={homeUrl} style={{ color: "black" }}>
                    {homeUrl}
                  </a>{" "}
                  only supports the browsers listed below. Please try again
                  using a supported browser.
                </p>
              )}
            </h6>
            <br />
            <ul style={{ listStyleType: "none" }}>
              <li>Chrome 29+</li>
              <li>Safari 7+</li>
              <li>Firefox 27+</li>
              <li>Edge 12+</li>
            </ul>

            <br />
            <br />
          </div>
        </div>
        <div className="footer-end pt-5">
          <div align="center" className="container-sm">
            <br />
            <Footer region={region} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
